const config = {
    basename: '',
    defaultPath: '/',
    borderRadius: 10,
    name: '',
    title: '',
    image: '',
    description: '',
    url: '',
    gridSpacing: 3,
    drawerWidth: 260,
    appDrawerWidth: 320,
    defaultTimezone: '+10'
};

export default config;
